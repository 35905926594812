<template>
  <div class="navigation-one">
    <div class="navigation_list">
      <div
        class="navigation_list_son"
        v-for="(item, index) in standard_list"
        :key="index"
      >
        <router-link
          :to="`/content/details/${item.id}/1`"
          target="_blank"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <img :src="item.icon" alt="" class="navigation_img" />
          <p class="navigation_title">{{ item.name }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationOnE',
  props: {
    standard_list: {
      type: Array,
      default: () => []
    },
  },
  setup () {
  }
}
</script>

<style scoped lang="less">
.navigation-one {
  width: 100%;
  height: 240px;
  background: #ffffff;
  .navigation_list {
    width: 1020px;
    height: 100%;
    margin: 0 auto;
    padding: 32px 0 45px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navigation_list_son {
      // width: 100;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 13px;
      .navigation_img {
        width: 100px;
        height: 100px;
        border-radius: 20px;
      }
      .navigation_title {
        margin-top: 19px;
        font-size: 18px;
        color: #808695;
        // text-align: center;
      }
    }
    .navigation_list_son:last-child {
      margin: 0;
    }
  }
}
</style>
