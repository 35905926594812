<template>
  <div class="Busines" style="position: relative;">
    <HomeCategory :categoryList="categoryList" />
    <div class="Busines_content">
      <div class="Busines_content_left">
        <p class="left_content">Exhibition</p>
        <p class="left_content">Area</p>
        <p class="left_line"></p>
        <p class='left_foot'>展示区</p>
      </div>
      <div class="Busines_content_right">
        <div class="Busines_content_right_son" v-for="(item, index) in nocase_list" :key="index">
          <router-link :to="`/content/details/${item.id}/2`" target="_blank"
            style="display: flex; flex-direction: column; align-items: center">
            <img :src="item.icon" alt="" class="Busines_content_right_img" />
            <p class="title1">{{ item.name }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue-demi';
import HomeCategory from './home-category.vue';
import { ref } from 'vue'
import { getcategory } from '@/api/FirstPage'
export default {
  name: 'Busines',
  components: {
    HomeCategory

  },
  props: {
    nocase_list: {
      type: Array,
      default: () => []
    },
  },
  setup() {
    const categoryList = ref([])
    const getcategoryList = async () => {
      const res = await getcategory()
      if (res.code == 0) {
        categoryList.value = res.data
        // console.log(res.data,'categoryList');
      }
    }
    onMounted(() => {
      getcategoryList()
    })
    return {
      categoryList,
      getcategoryList,

    }
  }
}
</script>

<style scoped lang="less">
.Busines {
  width: 100%;
  min-height: 620px;
  background: url(~@/assets/images/bg_1920x620@2x.png) no-repeat;
  background-size: 100% 100%;

  .Busines_content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 125px 0;
    display: flex;

    .Busines_content_left {
      width: 300px;
      height: 370px;
      background: url(~@/assets/images/about_bg@2x.png) no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      padding: 94px 0 0 0;

      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      .left_content {
        padding-left: 57px;
        font-size: 44px;
        font-weight: 900;
      }

      .left_line {
        width: 37px;
        height: 4px;
        background: #ffffff;
        text-align: left;
        margin-left: 57px;
      }

      .left_foot {
        font-size: 30px;
        font-weight: 700;
        margin-top: 20px;
        text-align: center;
      }
    }

    .Busines_content_right {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      .Busines_content_right_son {
        width: 210px;
        height: 180px;
        background: rgba(255, 255, 255, 0.95);
        margin-left: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;

        .title1 {
          font-size: 16px;
          color: #515a6e;
          margin-top: 15px;
        }

        .title2 {
          font-size: 7px;
          color: #808695;
        }
      }

      .Busines_content_right_img {
        width: 40px;
        height: 40px;
      }
    }
  }
}</style>
