<template>
  <div class="navigation-one">
    <div class="navigation_list">
      <a href="http://zxgk.court.gov.cn/">
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-01@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">失信人信息</p>
        </div>
      </a>
      <a
        href="https://www.court.gov.cn/susongyangshi-20.html"
        target="_blank"
      >
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-02@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">文书模板</p>
        </div>
      </a>
      <a href="http://www.court.gov.cn/fabu.html">
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-03@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">权威发布</p>
        </div>
      </a>
      <a href="https://wenshu.court.gov.cn/" target="_blank">
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-04@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">裁判文书查询</p>
        </div>
      </a>
      <a href="http://tingshen.court.gov.cn/" target="_blank">
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-05@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">庭审直播</p>
        </div>
      </a>
      <router-link to="/cost/agency-fare">
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-06@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">代理费速算</p>
        </div>
      </router-link>
      <a
        href="http://www.czcourt.gov.cn/sspt/lafuwu/calculator.aspx?type=999"
        target="_blank"
      >
        <div class="navigation_list_son">
          <img
            src="@/assets/images/tool-07@2x.png"
            alt=""
            class="navigation_img"
          />
          <p class="navigation_title">案件受理费速算</p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationTwo',
  setup () {
  }
}
</script>

<style scoped lang="less">
.navigation-one {
  width: 100%;
  height: 235px;
  background: #ffffff;
  .navigation_list {
    width: 1180px;
    height: 100%;
    margin: 0 auto;
    padding: 35px 0 0px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navigation_list_son {
      // width: 120px;
      min-height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .navigation_img {
        width: 120px;
        height: 120px;
      }
      .navigation_title {
        margin-top: 17px;
        font-size: 22px;
        color: #808695;
      }
      .navigation_title1 {
        font-size: 10px;
        color: #808695;
      }
    }
  }
}
</style>
