<template>
  <div class="Sales-service">
    <div class="Sales-service-content">
      <p style="margin-bottom: 45px">
        <span style="font-size: 26px; color: #17233d">热销服务</span>
        <span style="font-size: 14px; color: #808695; margin-left: 26px"
          >| 专长律师为您提供优质服务</span
        >
      </p>
      <div style="display: flex; flex-wrap: wrap">
        <router-link
          :to="`/product/${item.id}`"
          v-for="(item, index) in hot_list"
          :key="index"
          target="_blank"
        >
          <div class="Sales-service-content-list">
            <img :src="item.slider" alt="" class="Sales-service-img" />
            <p class="Sales-service-title1">
              <span>
                {{
                  item.name && item.name.length > 50
                    ? `${item.name.substring(0, 50)}...`
                    : item.name
                }}
              </span>
              <!-- <span style="color: #ff3c31"> ￥{{ item.price_selling }}-{{ item.max_price_selling }}</span> -->
            </p>
            <!-- <p class="Sales-service-title2">
              {{
                item.content && item.content.length > 12
                  ? `${item.content.substring(0, 20)}...`
                  : item.content
              }}
            </p> -->
            <p style="display:flex;justify-content: space-between;align-item:center;color:#999;padding:0 10px 10px;">
                <span>月销:{{item.month_sales}}</span>
                <span>年销:{{item.year_sales}}</span>
                <span>累计销量:{{item.total_sales}}</span>
            </p>
            <p
              style="
                color: #ff3c31;
                text-align: right;
                font-size: 18px;
                position: absolute;
                bottom: 10px;
                right: 10px;
              "
            >
              ￥{{ item.price_selling }}-{{ item.max_price_selling }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="Sales-service-all">
      <router-link to="/category/heat">
        <!-- <p class="all">查看全部</p> -->
      </router-link>
    </div>
    <div class="cut-off"></div>
    <div class="Sales-service-content">
      <p style="margin-bottom: 45px">
        <span style="font-size: 26px; color: #17233d">法律服务产品</span>
        <span style="font-size: 14px; color: #808695; margin-left: 26px"
          >| 专长律师为您提供优质服务</span
        >
      </p>
      <div style="display: flex; flex-wrap: wrap">
        <router-link
          :to="`/product/${item.id}`"
          v-for="(item, index) in goods_list"
          :key="index"
          target="_blank"
        >
          <div class="Sales-service-content-list">
            <img :src="item.slider" alt="" class="Sales-service-img" />
            <p class="Sales-service-title1">
              <span>
                {{
                  item.name && item.name.length > 50
                    ? `${item.name.substring(0, 50)}...`
                    : item.name
                }}
              </span>
              <!-- <span style="color: #ff3c31"> ￥{{ item.price_selling }}-{{ item.max_price_selling }}</span> -->
            </p>
            <!-- <p class="Sales-service-title2">
              {{
                item.content && item.content.length > 12
                  ? `${item.content.substring(0, 20)}...`
                  : item.content
              }}
            </p> -->
            <p style="display:flex;justify-content: space-between;align-item:center;color:#999;padding:0 10px 10px;">
                <span>月销:{{item.month_sales}}</span>
                <span>年销:{{item.year_sales}}</span>
                <span>累计销量:{{item.total_sales}}</span>
            </p>
            <p
              style="
                color: #ff3c31;
                text-align: right;
                font-size: 18px;
                position: absolute;
                bottom: 10px;
                right: 10px;
              "
            >
              ￥{{ item.price_selling }}-{{ item.max_price_selling }}
            </p>
            <!-- <p class="Sales-service-title2" v-html="item.remark"></p> -->
          </div>
        </router-link>
      </div>
    </div>
    <div class="Sales-service-all">
      <router-link to="/category/common">
        <!-- <p class="all">查看全部</p> -->
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesService',
  props: {
    hot_list: {
      type: Array,
      default: () => []
    },
    goods_list: {
      type: Array,
      default: () => []
    }
  },
  setup () {
  }
}
</script>

<style scoped lang="less">
.Sales-service {
  width: 100%;
  min-height: 930px;
  background: #ffffff;
  padding-bottom: 54px;
  .Sales-service-content {
    width: 1224px;
    margin-top: 45px;
    margin: 0 auto;
    padding: 119px 0 0 0;
    .Sales-service-content-list {
      width: 282px;
      min-height: 390px;
      background: #ffffff;
      box-shadow: 0px 4px 20px 0px rgba(103, 110, 135, 0.1);
      margin-bottom: 16px;
      margin-right: 24px;
      position: relative;
      .Sales-service-img {
        width: 100%;
        height: 282px;
      }
    }
    .Sales-service-content-list:nth-child(4n) {
      margin-right: 0;
    }
    .Sales-service-title1 {
      margin: 10px 10px 4px 10px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      // line-height: 20px;
      height: 40px;
      overflow: hidden;
      align-items: flex-start;
    }
    .Sales-service-title2 {
      margin: 0px 10px 4px 10px;
      font-size: 13px;
      color: #808695;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .Sales-service-all {
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 42px;
    .all {
      width: 184px;
      height: 38px;
      border: 1px solid #808695;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #515a6e;
    }
  }
}
.cut-off {
  width: 100%;
  height: 180px;
  background: url(~@/assets/images/bn1920x180@2x.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 54px;
}
</style>
