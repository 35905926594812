<template>
  <div class="home-category">
    <ul class="menu">
      <li v-for="currCategory in categoryList" :key="currCategory.id">
        <RouterLink :to="`/category/${currCategory.id}`" class="li_son">
          {{ currCategory.name }}
          <img src="@/assets/images/arrow12x12@2x.png" alt="" class="rightIcon" />
        </RouterLink>
        <!-- 弹层 -->
        <div class="layer" v-if="currCategory && currCategory.child.length > 0">
          <div class="layer-ul">
            <p v-for="item in currCategory.child" :key="item.id" class="layer-li">
              <!-- <RouterLink :to="`/category/${item.id}`"> -->
              {{ item.name }}
              <!-- </RouterLink> -->
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, reactive, ref, defineProps } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'HomeCategory',
  props: {
    categoryList: {
      type: Array,
      default: () => []
    },
  },
  setup() {





    
    // const selectStyle = (currCategory) => {
    //   // console.log(currCategory)
    // }
    // console.log(defineProps.categoryList,'1222222222');
    return {
      // selectStyle
    }
  }
}
</script>

<style scoped lang="less">
// 956 * 512 
.home-category {
  width: 260px;
  min-height: 500px;
  background: #ffffff;
  z-index: 99;
  position: fixed;
  left: 20px;
  top: 220px;

  // 10px 0
  // padding: 44px 0;
  // &:hover {
  //   .layer {
  //     display: block;
  //   }
  // }
  .layer {
    height: 500px;
    background: rgba(255, 255, 255);
    position: absolute;
    left: 200px;
    top: 0;
    display: none;
    // padding: 20px;
    z-index: 10;

    .layer-ul {
      // width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      writing-mode: vertical-lr;
      flex-wrap: wrap;
      padding: 10px;

      // 这里是整体的 li 样式
      .layer-li {
        width: 200px;
        // background: yellowgreen;
        color: #17233d;
        margin-right: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        writing-mode: horizontal-tb;

        // &:nth-child(3n) {
        //   margin-right: 0;
        // }
        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background: none;
        }
      }
    }
  }

  .menu {
    width: 100%;
    margin-top: 18px;

    li {
      width: 100%;
      text-align: center;
      line-height: 35px;
      margin-bottom: 5px;
      padding: 0 26px 0 44px;

      &:hover {
        .layer {
          display: block;
        }
      }

      .li_son {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .rightIcon {
        width: 12px;
        height: 12px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }

      a {
        margin-right: 4px;
        color: #17233d;

        &:first-child {
          font-size: 14px;
        }
      }
    }
  }
}</style>
