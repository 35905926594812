<template>
  <div class="page-home">
    <div class="home-entry" :style="{ backgroundImage: `url( ${background_img}) ` }">
      <!-- container 是版心分区 -->
      <div class="container">
        <!-- 左侧分类 -->
        <!-- 轮播图 -->
        <HomeBanner :list='cover' />
      </div>
    </div>
    <!-- 导航1 -->
    <NavigationOnE :standard_list="standard_list" />
    <!-- 非诉业务 -->
    <Busines :nocase_list="nocase_list" />
    <!-- 导航2 -->
    <NavigationTwo />
    <!--服务 -->
    <SalesService :hot_list="hot_list" :goods_list="goods_list" />
    <!-- 联系我们 -->
    <div class="contact">
      <div class="container">
        <div style="display: flex">
          <img src="@/assets/images/icon01@2x.png" alt="" class="container_img" />
          <p class="container_title">
            <span class="container_title1">专业优质</span>
            <span class="container_title2">资深律师提供优质服务</span>
          </p>
        </div>
        <div style="display: flex">
          <img src="@/assets/images/icon02@2x.png" alt="" class="container_img" />
          <p class="container_title">
            <span class="container_title1">一对一服务</span>
            <span class="container_title2">专属律师一对一为您服务</span>
          </p>
        </div>
        <div style="display: flex">
          <img src="@/assets/images/icon03@2x.png" alt="" class="container_img" />
          <p class="container_title">
            <span class="container_title1">便捷高效</span>
            <span class="container_title2">在线下单所需服务</span>
          </p>
        </div>
        <div style="display: flex">
          <img src="@/assets/images/icon04@2x.png" alt="" class="container_img" />
          <p class="container_title">
            <span class="container_title1">服务保障</span>
            <span class="container_title2">竭诚为您服务</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeCategory from './components/home-category.vue'
import HomeBanner from './components/home-banner.vue'
import NavigationOnE from './components/navigation-one.vue'
import Busines from './components/business.vue'
import NavigationTwo from './components/navigation-two.vue'
import SalesService from './components/Sales-service.vue'
import { ref, onMounted } from 'vue'
import { getFirst, getcategory } from '@/api/FirstPage'
import { getCartList } from '@/api/user'
import accountList from '@/utils/account.js'
import { useStore } from 'vuex'
export default {
  name: 'PageHome',
  components: {
    HomeBanner,
    NavigationOnE,
    Busines,
    NavigationTwo,
    SalesService
    // HomeSpecial
  },
  setup() {
    const store = useStore()
    const standard_list = ref([])
    const nocase_list = ref([])
    const hot_list = ref([])
    const goods_list = ref([])
    const background_img = ref('')
    const cover = ref('')
    const ispopover = ref(false)
    const getFirstList = async () => {
      const res = await getFirst()
      console.log(res)
      if (res.code == 0) {
        standard_list.value = res.data.standard_list
        nocase_list.value = res.data.nocase_list
        hot_list.value = res.data.hot_list
        goods_list.value = res.data.goods_list
        background_img.value = res.data.background_img
        cover.value = res.data.cover
      }
    }

    const getList = async () => {
      const res = await getCartList()
      if (res.code == 0) {
        store.commit('user/insertCart', res.data)
      }
    }
    const showPopover = () => {
      ispopover.value = true
    }
    const hidePopover = () => {
      ispopover.value = false
    }
    onMounted(() => {
      store.commit('account/setList', accountList)
      getFirstList()
      console.log(store.state.user.token)
      if (store.state.user.token) {
        getList()
      }

    })
    return {
      getFirstList,
      standard_list,
      nocase_list,
      hot_list,
      goods_list,
      // getcategoryList,
      // categoryList,
      ispopover,
      showPopover,
      hidePopover,
      getList,
      background_img,
      cover
    }
  }
}
</script>

<style scoped lang="less">
.page-home {
  .home-entry {
    width: 100%;
    min-height: 700px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .container {
      width: 1200px;
      // min-height: 616px;
      margin: 0 auto;
      padding-top: 94px;
      display: flex;
    }
  }
}

.contact {
  width: 100%;
  height: 120px;
  background: #f8f8f9;

  .container {
    width: 1124px;
    margin: 0 auto;
    padding: 41px 0;
    display: flex;
    justify-content: space-between;

    .container_img {
      width: 36px;
      height: 36px;
    }

    .container_title {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
    }

    .container_title1 {
      font-size: 16px;
      margin-bottom: 2px;
    }

    .container_title2 {
      font-size: 12px;
      color: #808695;
    }
  }
}
</style>
